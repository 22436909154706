import * as React from 'react'
import { Wrapper } from '../layout'
import theme from '../styles/theme'
import GlobalStyles from '../styles/globalStyles'
import Head from '../components/Head'
import styled, { keyframes, ThemeProvider } from 'styled-components'
import { GatsbyImageFluidProps } from 'gatsby-image'
import { BarraNavegacion } from '../components/BarraNavegacion'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import { media } from '../styles/styleUtils'
import { PieDePagina } from '../components/PieDePagina'

interface IndexPageProps {
  location: {
    pathname: string
  }
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
      }
    }
  }
}

export const Parrafo = styled.p`
  text-align: justify;
  font-size: 1.2rem;
  margin: 2rem 0 2rem 0;
`
const scale = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
`

const ImagenQuienesSomos = styled(Img)`
  width: 100%;
  object-fit: cover;
  margin-bottom: 0.4rem;
  break-inside: avoid;
`

const WrapperMultimedia = styled.div`
  display: block;
  column-count: 1;
  column-gap: 0.4rem;
  margin-bottom: 0.4rem;
  position: relative;
  overflow: hidden;
  @media (min-width: 1200px) {
    column-count: 4;
  }
  @media (min-width: 900px) and (max-width: 1199px) {
    column-count: 4;
  }
  @media (min-width: 700px) and (max-width: 899px) {
    column-count: 3;
  }
  @media (min-width: 400px) and (max-width: 699px) {
    column-count: 1;
  }
`

const TituloSeccion = styled.h3`
  position: absolute;
  bottom: 1.5rem;
  color: white;
  font-weight: 900;
  right: 1.5rem;
  left: 1.5rem;
  font-size: 1.3rem;
  text-align: right;
  text-shadow: 2px 2px grey;
  z-index: 2;
  ${media.md`
    font-size: 1rem;
  `}
  ${media.sm`
    font-size: 1rem;
    bottom: 1.5rem;
    right: 1.5rem;
  `}
`
export const WrapperBody = styled.div`
  display: block;
  ${media.md`
    margin: 1rem;
  `}
`
const WrapperImageCabeceraDerecha = styled.div`
  position: relative;
  justify-content: center;

  width: 100%;
  overflow: hidden;
  text-align: center;
  ${media.md`
  `}
`

export default ({ data, location }: IndexPageProps) => {
  const ImageQueryMultimedia = useStaticQuery(graphql`
    query ImageQueryMultimedia {
      CanutillosRellenos: file(
        relativePath: { eq: "imagenes/multimedia/canutillos-rellenos.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ChuletaDeTernera: file(
        relativePath: { eq: "imagenes/la-carta/Endivias con marisco.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      EsparragosRellenos: file(
        relativePath: { eq: "imagenes/la-carta/3 espárragos rellenos.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      CogoteDeMerluza: file(
        relativePath: {
          eq: "imagenes/la-carta/Cogote de merluza al horno.jpeg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      MedallonesDeSolomillo: file(
        relativePath: {
          eq: "imagenes/la-carta/Medallones de solomillo con foie y salsa de Oporto.jpeg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      RodaballoALaDonostiarra: file(
        relativePath: {
          eq: "imagenes/la-carta/Rodaballo a la Donostiarra.jpeg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ManitasDeCerdo: file(
        relativePath: {
          eq: "imagenes/la-carta/Manitas de cerdo rellenas de foie y jamón ibérico con salsa de Pedro Ximénez.jpeg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ConfitDePato: file(
        relativePath: {
          eq: "imagenes/la-carta/Confit de pato con salsa agridulce.jpeg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      RevueltoDeHongosConFoie: file(
        relativePath: {
          eq: "imagenes/la-carta/Revuelto de hongosc on foie.jpeg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      CrepesDeCuajada: file(
        relativePath: {
          eq: "imagenes/la-carta/Crepes rellenos de cuajada con salsa de naranja.jpeg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      TorrijaDeBrioche: file(
        relativePath: {
          eq: "imagenes/la-carta/Torrija de Brioche caramelizada sobre sopa de vainilla y helado de galleta María.jpeg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      TrufasConNata: file(
        relativePath: { eq: "imagenes/la-carta/Trufas con nata.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      CrepesConHelado: file(
        relativePath: {
          eq: "imagenes/la-carta/Crepes con helado de café y chocolate caliente.jpeg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <>
      <Wrapper>
        <GlobalStyles />
        <Head pathname={location.pathname} />
        <ThemeProvider theme={theme}>{data}</ThemeProvider>
        <BarraNavegacion />
        <WrapperBody>
          <WrapperMultimedia>
            <WrapperImageCabeceraDerecha>
              <TituloSeccion>Endivias con marisco</TituloSeccion>
              <ImagenQuienesSomos
                alt={'Endivias con marisco'}
                fluid={
                  ImageQueryMultimedia.ChuletaDeTernera.childImageSharp.fluid
                }
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            <WrapperImageCabeceraDerecha>
              <TituloSeccion>Espárragos rellenos</TituloSeccion>
              <ImagenQuienesSomos
                alt={'3 espárragos rellenos'}
                fluid={
                  ImageQueryMultimedia.EsparragosRellenos.childImageSharp.fluid
                }
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            <WrapperImageCabeceraDerecha>
              <TituloSeccion>Revuelto de hongos con Foie</TituloSeccion>
              <ImagenQuienesSomos
                alt={'Revuelto de hongos con Foie'}
                fluid={
                  ImageQueryMultimedia.RevueltoDeHongosConFoie.childImageSharp
                    .fluid
                }
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            <WrapperImageCabeceraDerecha>
              <TituloSeccion>Cogote de merluza al horno</TituloSeccion>
              <ImagenQuienesSomos
                alt={'Cogote de merluza al horno'}
                fluid={
                  ImageQueryMultimedia.CogoteDeMerluza.childImageSharp.fluid
                }
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            {/* </WrapperMultimedia>
          <WrapperMultimedia> */}
            <WrapperImageCabeceraDerecha>
              <TituloSeccion>Rodaballo a la Donostiarra</TituloSeccion>
              <ImagenQuienesSomos
                alt={'Rodaballo a la Donostiarra'}
                fluid={
                  ImageQueryMultimedia.RodaballoALaDonostiarra.childImageSharp
                    .fluid
                }
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            <WrapperImageCabeceraDerecha>
              <TituloSeccion>
                Medallones de solomillo con foie y salsa de Oporto
              </TituloSeccion>
              <ImagenQuienesSomos
                alt={'Medallones de solomillo con foie y salsa de Oporto'}
                fluid={
                  ImageQueryMultimedia.MedallonesDeSolomillo.childImageSharp
                    .fluid
                }
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            <WrapperImageCabeceraDerecha>
              <TituloSeccion>
                Manitas de cerdo rellenas de foie y jamón ibérico con salsa de
                Pedro Ximénez
              </TituloSeccion>
              <ImagenQuienesSomos
                alt={
                  'Manitas de cerdo rellenas de foie y jamón ibérico con salsa de Pedro Ximénez'
                }
                fluid={
                  ImageQueryMultimedia.ManitasDeCerdo.childImageSharp.fluid
                }
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            <WrapperImageCabeceraDerecha>
              <TituloSeccion>Confit de pato con salsa agridulce</TituloSeccion>
              <ImagenQuienesSomos
                alt={'Confit de pato con salsa agridulce'}
                fluid={ImageQueryMultimedia.ConfitDePato.childImageSharp.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            
            <WrapperImageCabeceraDerecha>
              <TituloSeccion>
                Crepes rellenos de cuajada con salsa de naranja
              </TituloSeccion>
              <ImagenQuienesSomos
                alt={'Crepes rellenos de cuajada con salsa de naranja'}
                fluid={
                  ImageQueryMultimedia.CrepesDeCuajada.childImageSharp.fluid
                }
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            <WrapperImageCabeceraDerecha>
              <TituloSeccion>
                Torrija de Brioche caramelizada sobre sopa de vainilla y helado
                de galleta María
              </TituloSeccion>
              <ImagenQuienesSomos
                alt={
                  'Torrija de Brioche caramelizada sobre sopa de vainilla y helado de galleta María'
                }
                fluid={
                  ImageQueryMultimedia.TorrijaDeBrioche.childImageSharp.fluid
                }
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            <WrapperImageCabeceraDerecha>
              <TituloSeccion>Trufas con nata</TituloSeccion>
              <ImagenQuienesSomos
                alt={'Trufas con nata'}
                fluid={ImageQueryMultimedia.TrufasConNata.childImageSharp.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
            <WrapperImageCabeceraDerecha>
              <TituloSeccion>
                Crepes con helado de café y chocolate caliente
              </TituloSeccion>
              <ImagenQuienesSomos
                alt={'Crepes con helado de café y chocolate caliente'}
                fluid={
                  ImageQueryMultimedia.CrepesConHelado.childImageSharp.fluid
                }
                objectFit="cover"
                objectPosition="50% 50%"
              ></ImagenQuienesSomos>
            </WrapperImageCabeceraDerecha>
          </WrapperMultimedia>
        </WrapperBody>

        <PieDePagina />
      </Wrapper>
    </>
  )
}
